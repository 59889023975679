import { render, staticRenderFns } from "./Master.vue?vue&type=template&id=7071f7da"
import script from "./Master.ts?vue&type=script&lang=ts&external"
export * from "./Master.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports